import {BrandKitColors} from '@cheddarup/core'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {SharpImage} from 'src/components/SharpImage'

interface TopBannerProps extends React.ComponentPropsWithoutRef<'div'> {
  topBanner: Api.GroupPageSection
  brandKitColors: BrandKitColors
}

const TopBanner: React.FC<TopBannerProps> = ({
  topBanner,
  style,
  brandKitColors,
  ...restProps
}) => {
  const media = WebUI.useMedia()

  return (
    <div
      style={{
        backgroundColor: topBanner.background_image
          ? topBanner.details.complementaryColor
          : brandKitColors[topBanner.background_color],
        ...style,
      }}
      {...restProps}
    >
      <div
        className={WebUI.cn(
          'mx-auto flex max-w-[min(1500px,100%)] flex-col overflow-hidden bg-center bg-cover',
          topBanner.background_image ? 'aspect-[3/1] min-h-[500px]' : 'py-10',
        )}
        style={{
          backgroundImage: topBanner.background_image
            ? `url(${topBanner.background_image.edited_image_url ?? topBanner.background_image.url})`
            : undefined,
        }}
      >
        {(topBanner.headline || topBanner.logo) && (
          <div className="relative mx-auto flex h-full w-full max-w-[1100px] grow flex-col justify-center">
            <div
              className={WebUI.cn(
                'flex flex-col items-center gap-10 self-center rounded-[30px] px-10 py-12 md:max-h-[290px] md:flex-row xl:self-start',
                !topBanner.background_image && 'self-center',
                topBanner.background_image && 'md:max-w-3xl',
              )}
              style={{
                color: Util.getReadableColor(
                  brandKitColors[topBanner.background_color],
                  undefined,
                  true,
                ),
                backgroundColor: brandKitColors[topBanner.background_color],
              }}
            >
              {topBanner.logo && (
                <SharpImage
                  className="shrink-0 rounded-extended"
                  alt=""
                  image={topBanner.logo}
                  height={media.sm ? 170 : 130}
                  width={media.sm ? 170 : 130}
                />
              )}
              <div className="flex flex-col gap-4">
                <WebUI.Heading className="font-accentAlt text-h-6 md:text-h-4">
                  {topBanner.headline}
                </WebUI.Heading>
                {!!topBanner.tagline && (
                  <WebUI.Text className="font-light text-ds-lg leading-heading">
                    {topBanner.tagline}
                  </WebUI.Text>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TopBanner
