import * as WebUI from '@cheddarup/web-ui'
import {useLocation, useNavigate} from 'react-router-dom'
import React from 'react'
import {api} from '@cheddarup/api-client'
import {isAccountDetailsFieldsFilled} from '@cheddarup/core'
import {NavListItemNavLink} from 'src/components/NavListItemNavLink'

const AccountSettingsLayout = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({className, children, ...restProps}, forwardedRef) => {
  const media = WebUI.useMedia()
  const location = useLocation()
  const navigate = useNavigate()
  return (
    <WebUI.Modal
      aria-label="Account settings"
      className={
        '[&_>_.ModalContentView]:h-[calc(100%-theme(spacing.16))] [&_>_.ModalContentView]:max-w-screen-lg'
      }
      onDidHide={() => navigate({pathname: '..', search: location.search})}
    >
      <WebUI.ModalCloseButton />
      <WebUI.HStack className="grow overflow-hidden">
        {media.md ? (
          <Sidebar />
        ) : (
          <WebUI.Drawer>
            <Sidebar className="shrink-0 grow basis-auto" />
          </WebUI.Drawer>
        )}
        <WebUI.VStack
          ref={forwardedRef}
          className={WebUI.cn(
            'flex-auto overflow-y-auto px-6 py-8 md:px-12 md:py-16',
            className,
          )}
          {...restProps}
        >
          {children}
        </WebUI.VStack>
      </WebUI.HStack>
    </WebUI.Modal>
  )
})

// MARK: – Sidebar

const Sidebar = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const location = useLocation()
  const isAnyFieldNeededQuery = api.auth.session.useQuery(undefined, {
    select: (session) =>
      session.stripe_data.fieldsNeeded.length > 0 &&
      isAccountDetailsFieldsFilled(session.user),
  })
  const isCanadaFieldNeededQuery = api.auth.session.useQuery(undefined, {
    select: (session) =>
      session.user.currency === 'cad' && session.user.editable,
  })
  const isAlertsNotEmptyQuery = api.alerts.list.useQuery(undefined, {
    select: (alerts) => alerts.length > 0,
  })

  function getLinkTo(pathname: string) {
    return {
      pathname,
      search: location.search,
    }
  }

  return (
    <WebUI.VStack
      className={WebUI.cn(
        'w-[320px] min-w-[320px] gap-4 bg-natural-80 px-10 py-15',
        className,
      )}
      {...restProps}
    >
      <WebUI.Heading className="font-bold uppercase" as="h5">
        My Account
      </WebUI.Heading>

      <WebUI.NavList className="ml-4">
        <WebUI.NavListContent>
          <WebUI.NavListSub
            disclosure={
              <WebUI.NavListItem>
                <WebUI.NavListSubDisclosure>Profile</WebUI.NavListSubDisclosure>
              </WebUI.NavListItem>
            }
          >
            <WebUI.NavListItem>
              <NavListItemNavLink to={getLinkTo('display-name')}>
                Display Name
              </NavListItemNavLink>
            </WebUI.NavListItem>
            <WebUI.NavListItem>
              <NavListItemNavLink to={getLinkTo('email-and-password')}>
                Email and Password
              </NavListItemNavLink>
            </WebUI.NavListItem>
            <WebUI.NavListItem>
              <NavListItemNavLink
                iconAfter={
                  isAnyFieldNeededQuery.data && (
                    <WebUI.PhosphorIcon
                      icon="warning-circle-fill"
                      className="text-orange-50"
                      width={22}
                    />
                  )
                }
                to={getLinkTo('account-details')}
              >
                Account Details
              </NavListItemNavLink>
            </WebUI.NavListItem>
            {isCanadaFieldNeededQuery.data && (
              <WebUI.NavListItem>
                <NavListItemNavLink
                  iconAfter={
                    <WebUI.PhosphorIcon
                      className="text-orange-50"
                      width={22}
                      icon="warning-circle-fill"
                    />
                  }
                  to={getLinkTo('canada-verification')}
                >
                  Canada Verification
                </NavListItemNavLink>
              </WebUI.NavListItem>
            )}
            <WebUI.NavListItem>
              <NavListItemNavLink to={getLinkTo('statement-descriptor')}>
                Statement Descriptor
              </NavListItemNavLink>
            </WebUI.NavListItem>
            <WebUI.NavListItem>
              <NavListItemNavLink to={getLinkTo('security')}>
                Security
              </NavListItemNavLink>
            </WebUI.NavListItem>
            <WebUI.NavListItem>
              <NavListItemNavLink to={getLinkTo('plan-billing')}>
                Plan and Billing
              </NavListItemNavLink>
            </WebUI.NavListItem>

            <WebUI.NavListItem>
              <NavListItemNavLink to={getLinkTo('payment-methods')}>
                Payment Methods
              </NavListItemNavLink>
            </WebUI.NavListItem>
            <WebUI.NavListItem>
              <NavListItemNavLink to={getLinkTo('notification-settings')}>
                Notifications
              </NavListItemNavLink>
            </WebUI.NavListItem>
          </WebUI.NavListSub>

          <WebUI.NavListItem>
            <NavListItemNavLink to={getLinkTo('withdrawal-settings')}>
              Withdrawals
            </NavListItemNavLink>
          </WebUI.NavListItem>

          <WebUI.NavListSub
            disclosure={
              <WebUI.NavListItem>
                <WebUI.NavListSubDisclosure>
                  Brand Kit
                </WebUI.NavListSubDisclosure>
              </WebUI.NavListItem>
            }
          >
            <WebUI.NavListItem>
              <NavListItemNavLink to={getLinkTo('brand-kit-logo')}>
                Logo
              </NavListItemNavLink>
            </WebUI.NavListItem>
            <WebUI.NavListItem>
              <NavListItemNavLink to={getLinkTo('brand-kit-palette')}>
                Palette
              </NavListItemNavLink>
            </WebUI.NavListItem>
            <WebUI.NavListItem>
              <NavListItemNavLink to={getLinkTo('brand-kit-footer')}>
                Page Footer
              </NavListItemNavLink>
            </WebUI.NavListItem>
            <WebUI.NavListItem>
              <NavListItemNavLink to={getLinkTo('brand-kit-email')}>
                Emails
              </NavListItemNavLink>
            </WebUI.NavListItem>
          </WebUI.NavListSub>

          <WebUI.NavListItem>
            <NavListItemNavLink to={getLinkTo('zapier-key')}>
              Integrations
            </NavListItemNavLink>
          </WebUI.NavListItem>

          {isAlertsNotEmptyQuery.data && (
            <WebUI.NavListItem>
              <NavListItemNavLink
                iconAfter={
                  <WebUI.PhosphorIcon
                    icon="warning-circle-fill"
                    className="text-orange-50"
                    width={20}
                  />
                }
                to={getLinkTo('alerts')}
              >
                Alerts
              </NavListItemNavLink>
            </WebUI.NavListItem>
          )}
        </WebUI.NavListContent>
      </WebUI.NavList>
    </WebUI.VStack>
  )
}

export default AccountSettingsLayout
