import {AutoformatRule} from '@udecode/plate-autoformat'
import {AutoformatPlugin} from '@udecode/plate-autoformat/react'
import {BlockquotePlugin} from '@udecode/plate-block-quote/react'
import {HeadingPlugin} from '@udecode/plate-heading/react'
import {HEADING_KEYS} from '@udecode/plate-heading'
import {HorizontalRulePlugin} from '@udecode/plate-horizontal-rule/react'
import {toggleList, unwrapList} from '@udecode/plate-list'
import {
  BulletedListPlugin,
  NumberedListPlugin,
  ListItemPlugin,
  ListPlugin,
} from '@udecode/plate-list/react'
import {LinkPlugin} from '@udecode/plate-link/react'
import {
  BoldPlugin,
  ItalicPlugin,
  StrikethroughPlugin,
  UnderlinePlugin,
} from '@udecode/plate-basic-marks/react'
import {
  MarkdownPlugin,
  deserializeMd,
  serializeMd,
} from '@udecode/plate-markdown'
import {SoftBreakPlugin, ExitBreakPlugin} from '@udecode/plate-break/react'
import {ResetNodePlugin} from '@udecode/plate-reset-node/react'
import {TrailingBlockPlugin} from '@udecode/plate-trailing-block'
import {SelectOnBackspacePlugin} from '@udecode/plate-select'
import {
  TElement,
  collapseSelection,
  findNode,
  getParentNode,
  insertEmptyElement,
  insertNodes,
  isBlock,
  isBlockAboveEmpty,
  isElement,
  isSelectionAtBlockStart,
  setNodes,
  toggleBlock,
  AnyPluginConfig,
  SlateEditor,
} from '@udecode/plate-common'
import {ParagraphPlugin} from '@udecode/plate-common/react'
import {
  CreateHOCOptions,
  PlateEditor,
  PlaceholderProps,
  Plate,
  PlateContent,
  PlateContentProps,
  focusEditor,
  useEditorRef,
  usePlateEditor,
} from '@udecode/plate-common/react'
import {Editor, Transforms} from 'slate'
import {useForkRef, useLiveRef} from '@cheddarup/react-util'
import React, {useEffect, useImperativeHandle, useRef, useState} from 'react'

import {PhosphorIcon} from '../../icons'
import {Separator} from '../Separator'
import {
  PlateLinkFloatingToolbar,
  PlateLinkToolbarButton,
  PlateListToolbarButton,
  PlateMarkToolbarButton,
  PlateToolbarItem,
  createPlateUI,
} from './PlateElements'
import {Toolbar} from '../Toolbar'
import {cn, getPossibleLinks} from '../../utils'

export interface RichTextEditorInstance extends PlateEditor {
  setMarkdownValue: (markdownValue: string) => void
}

export interface RichTextEditorProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>,
    Pick<
      RichTextEditorInnerProps,
      'name' | 'disabled' | 'readOnly' | 'autoFocus' | 'placeholder'
    > {
  plugins?: AnyPluginConfig[]
  placeholders?: Array<CreateHOCOptions<PlaceholderProps>>
  footer?: React.ReactNode
  initialMarkdownValue?: string
  onMarkdownValueChange?: (newMarkdown: string) => void
}

const resetBlockTypesCommonRule = {
  types: [BlockquotePlugin.key],
  defaultType: ParagraphPlugin.key,
}

export const RichTextEditor = React.forwardRef<
  RichTextEditorInstance,
  RichTextEditorProps
>(
  (
    {
      'aria-invalid': ariaInvalid,
      disabled,
      readOnly,
      placeholder,
      plugins: pluginsProp,
      placeholders = [],
      initialMarkdownValue: initialMarkdownValueProp,
      onMarkdownValueChange,
      name,
      id,
      className,
      children,
      footer,
      ...restProps
    },
    forwardedRef,
  ) => {
    const ownRef = useRef<RichTextEditorInstance>(null)
    const ref = useForkRef(ownRef, forwardedRef)
    const placeholdersRef = useLiveRef(placeholders)

    const plateEditor = usePlateEditor(
      {
        id,
        // shouldNormalizeEditor: true,
        override: {
          components: createPlateUI(undefined, {
            placeholders: placeholdersRef.current,
          }),
        },
        plugins: [
          // elements
          ParagraphPlugin,
          BlockquotePlugin,
          HeadingPlugin,
          LinkPlugin.configure({
            options: {
              forceSubmit: true,
              getUrlHref: (url) =>
                getPossibleLinks(url).find((l) => l.isLink)?.href,
              defaultLinkAttributes: {
                target: '_blank',
                rel: 'noopener noreferrer',
              },
            },
            render: {
              afterEditable: () => <PlateLinkFloatingToolbar />,
            },
          }),
          ListPlugin,
          HorizontalRulePlugin,

          // marks
          BoldPlugin,
          ItalicPlugin,
          StrikethroughPlugin,
          UnderlinePlugin,

          // utils
          TrailingBlockPlugin.configure({
            options: {type: ParagraphPlugin.key},
          }),
          ResetNodePlugin.configure({
            options: {
              rules: [
                {
                  ...resetBlockTypesCommonRule,
                  hotkey: 'Enter',
                  predicate: isBlockAboveEmpty,
                },
                {
                  ...resetBlockTypesCommonRule,
                  hotkey: 'Backspace',
                  predicate: isSelectionAtBlockStart,
                },
              ],
            },
          }),
          SoftBreakPlugin.configure({
            options: {
              rules: [
                {hotkey: 'shift+enter'},
                {
                  hotkey: 'enter',
                  query: {
                    allow: [BlockquotePlugin.key],
                  },
                },
              ],
            },
          }),
          ExitBreakPlugin.configure({
            options: {
              rules: [
                {
                  hotkey: 'mod+enter',
                },
                {
                  hotkey: 'mod+shift+enter',
                  before: true,
                },
                {
                  hotkey: 'enter',
                  query: {
                    start: true,
                    end: true,
                    allow: Object.values(HEADING_KEYS),
                  },
                  relative: true,
                  level: 1,
                },
              ],
            },
          }),
          SelectOnBackspacePlugin.configure({
            options: {query: {allow: [HorizontalRulePlugin.key]}},
          }),
          AutoformatPlugin.configure({
            options: {
              rules: [
                ...autoformatBlocks,
                ...autoformatLists,
                ...autoformatMarks,
              ],
              enableUndoOnDelete: true,
            },
          }),

          // serialization
          MarkdownPlugin,

          ...(pluginsProp ?? []),
        ],
      },
      [id, pluginsProp],
    )

    const [initialMarkdownValue] = useState(initialMarkdownValueProp)
    useEffect(() => {
      if (initialMarkdownValue != null) {
        // ownRef.current?.setMarkdownValue(initialMarkdownValue)
      }
    }, [initialMarkdownValue])

    return (
      <div
        aria-disabled={disabled}
        aria-invalid={ariaInvalid}
        data-read-only={readOnly}
        id={id}
        className={cn(
          'flex min-h-[120px] flex-col rounded font-body font-light shadow-[0_0_0_1px_theme(colors.natural.70)]',
          'aria-invalid:shadow-[0_0_0_1px_theme(colors.orange.50)]',
          'aria-disabled:pointer-events-none aria-disabled:cursor-not-allowed aria-disabled:opacity-50',
          'focus-within:shadow-[0_0_0_1px_theme(colors.teal.50)]',
          className,
        )}
        {...restProps}
      >
        <Plate
          editor={plateEditor}
          onChange={(options) => {
            onMarkdownValueChange?.(
              normalizeMarkdownLineBreaks(serializeMd(options.editor)),
            )
          }}
        >
          {children}
          <RichTextEditorInner
            ref={ref}
            aria-invalid={ariaInvalid}
            id={id}
            name={name}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeholder}
          />
          {footer}
        </Plate>
      </div>
    )
  },
)

// MARK: – RichTextEditorInner

interface RichTextEditorInnerProps extends PlateContentProps {}

const RichTextEditorInner = React.forwardRef<
  RichTextEditorInstance,
  RichTextEditorInnerProps
>(({disabled, readOnly, id, className, ...restProps}, forwardedRef) => {
  const editor = useEditorRef(id)

  useImperativeHandle(
    forwardedRef,
    () => ({
      setMarkdownValue: (markdownValue: string) => {
        try {
          if (editor) {
            Transforms.select(editor as any, [])
            Transforms.delete(editor as any, {
              at: {
                anchor: Editor.start(editor as any, []),
                focus: Editor.end(editor as any, []),
              },
            })
            Transforms.insertFragment(
              editor as any,
              deserializeMd(editor, markdownValue),
            )
          }
        } catch {
          // noop
        }
      },
      ...(editor as any),
    }),
    [editor],
  )

  return (
    <PlateContent
      aria-disabled={disabled}
      id={id}
      className={cn(
        'RichTextEditor',
        'grow px-2 py-2',
        'transition-colors duration-100 ease-in-out',
        'disabled:pointer-events-none aria-disabled:pointer-events-none',
        'hover:[&:not(:focus):not([aria-invalid=true]):not(:disabled):not([aria-disabled=true])]:bg-inputHoverBackground',
        '[&_[data-slate-placeholder]]:!w-[calc(100%-theme(spacing.2)*2)] [&_[data-slate-placeholder]]:!max-w-[calc(100%-theme(spacing.2)*2)] [&_[data-slate-placeholder]]:!py-2 [&_[data-slate-placeholder]]:!font-light [&_[data-slate-placeholder]]:!not-italic [&_[data-slate-placeholder]]:!text-inputPlaceholder [&_[data-slate-placeholder]]:!opacity-100',
        'focus-visible:outline-none',
        '*:before:!text-inputPlaceholder *:before:!opacity-100',
        '[&_ol]:!ml-[var(--ifm-list-left-padding)] [&_ol]:![padding-inline-start:24px]',
        '[&_ul]:!ml-[var(--ifm-list-left-padding)] [&_ul]:![padding-inline-start:24px] [&_ul]:[list-style-type:initial]',
        className,
      )}
      disabled={disabled}
      readOnly={readOnly || disabled}
      autoFocus={false}
      spellCheck={false}
      {...restProps}
    />
  )
})

// MARK: – RichTextEditorToolbar

export interface RichTextEditorToolbarProps
  extends React.ComponentPropsWithoutRef<'div'> {
  rootClassName?: string
  pick?: Array<
    | typeof HEADING_KEYS.h1
    | typeof BoldPlugin.key
    | typeof ItalicPlugin.key
    | typeof LinkPlugin.key
    | typeof NumberedListPlugin.key
    | typeof BulletedListPlugin.key
    | typeof HorizontalRulePlugin.key
  >
}

export const RichTextEditorToolbar = React.forwardRef<
  HTMLDivElement,
  RichTextEditorToolbarProps
>(({rootClassName, pick, className, children, ...restProps}, forwardedRef) => {
  const editor = useEditorRef()

  const currentBlockNodeEntry = findNode<TElement>(editor, {
    match: (n) => isBlock(editor, n),
  })

  return (
    <Toolbar className={cn('RichTextEditorToolbar', rootClassName)}>
      <div
        ref={forwardedRef}
        className={cn(
          'RichTextEditorToolbar-content',
          'flex grow flex-col justify-start gap-3 border-b p-3 sm:flex-row sm:justify-between',
          '[&_.slate-ToolbarButton-active]:!text-teal-50 [&_.slate-ToolbarButton:hover]:!text-teal-50',
          className,
        )}
        {...restProps}
      >
        <div className="RichTextEditorToolbar-contentMain flex flex-[2] flex-row">
          {(!pick || pick.includes(HEADING_KEYS.h1)) && (
            <div className="flex flex-[1] flex-row">
              <PlateToolbarItem
                pressed={currentBlockNodeEntry?.[0].type === HEADING_KEYS.h1}
                onClick={() => {
                  toggleBlock(editor, {type: HEADING_KEYS.h1})
                  collapseSelection(editor)
                  focusEditor(editor)
                }}
              >
                <PhosphorIcon icon="text-h" />
              </PlateToolbarItem>
            </div>
          )}

          <div className="RichTextEditorToolbar-decorators flex flex-[1] flex-row gap-3">
            <Separator orientation="vertical" variant="primary" />
            {(!pick || pick.includes(BoldPlugin.key)) && (
              <PlateMarkToolbarButton nodeType={BoldPlugin.key}>
                <PhosphorIcon icon="text-bolder" />
              </PlateMarkToolbarButton>
            )}
            {(!pick || pick.includes(ItalicPlugin.key)) && (
              <PlateMarkToolbarButton nodeType={ItalicPlugin.key}>
                <PhosphorIcon icon="text-italic" />
              </PlateMarkToolbarButton>
            )}
            {(!pick || pick.includes(LinkPlugin.key)) && (
              <PlateLinkToolbarButton />
            )}
            <Separator orientation="vertical" variant="primary" />
            {(!pick || pick.includes(NumberedListPlugin.key)) && (
              <PlateListToolbarButton nodeType={NumberedListPlugin.key} />
            )}
            {(!pick || pick.includes(BulletedListPlugin.key)) && (
              <PlateListToolbarButton nodeType={BulletedListPlugin.key} />
            )}
            {(!pick || pick.includes(HorizontalRulePlugin.key)) && (
              <PlateToolbarItem
                onClick={() => {
                  insertEmptyElement(editor, HorizontalRulePlugin.key, {
                    select: true,
                    nextBlock: true,
                  })
                  focusEditor(editor)
                }}
              >
                <PhosphorIcon icon="arrows-out-line-vertical" />
              </PlateToolbarItem>
            )}
          </div>
        </div>

        <div className="RichTextEditorToolbar-extra flex flex-[1] flex-row xs:justify-start justify-end gap-3">
          {children}
        </div>
      </div>
    </Toolbar>
  )
})

// MARK: – Formatting helpers

function preFormat(editor: SlateEditor) {
  return unwrapList(editor)
}

function format(editor: SlateEditor, customFormatting: () => void) {
  if (editor.selection) {
    const parentEntry = getParentNode(editor, editor.selection)
    if (!parentEntry) {
      return
    }
    const [node] = parentEntry
    if (isElement(node)) {
      customFormatting()
    }
  }
}

function formatList(editor: SlateEditor, elementType: string) {
  return format(editor, () =>
    toggleList(editor, {
      type: elementType,
    }),
  )
}

const autoformatBlocks: AutoformatRule[] = [
  {
    mode: 'block',
    type: HEADING_KEYS.h1,
    match: '# ',
    preFormat,
  },
  {
    mode: 'block',
    type: HEADING_KEYS.h2,
    match: '## ',
    preFormat,
  },
  {
    mode: 'block',
    type: HEADING_KEYS.h3,
    match: '### ',
    preFormat,
  },
  {
    mode: 'block',
    type: HEADING_KEYS.h4,
    match: '#### ',
    preFormat,
  },
  {
    mode: 'block',
    type: HEADING_KEYS.h5,
    match: '##### ',
    preFormat,
  },
  {
    mode: 'block',
    type: HEADING_KEYS.h6,
    match: '###### ',
    preFormat,
  },
  {
    mode: 'block',
    type: BlockquotePlugin.key,
    match: '> ',
    preFormat,
  },
  {
    mode: 'block',
    type: HorizontalRulePlugin.key,
    match: ['---', '—-', '___ '],
    preFormat,
    format: (editor) => {
      setNodes(editor, {type: HorizontalRulePlugin.key})
      insertNodes(editor, {type: ParagraphPlugin.key, children: [{text: ''}]})
    },
  },
]

const autoformatLists: AutoformatRule[] = [
  {
    mode: 'block',
    type: ListItemPlugin.key,
    match: ['* ', '- '],
    preFormat,
    format: (editor) => formatList(editor, BulletedListPlugin.key),
  },
  {
    mode: 'block',
    type: ListItemPlugin.key,
    match: ['1. ', '1) '],
    preFormat,
    format: (editor) => formatList(editor, NumberedListPlugin.key),
  },
]

const autoformatMarks: AutoformatRule[] = [
  {
    mode: 'mark',
    type: [BoldPlugin.key, ItalicPlugin.key],
    match: '***',
  },
  {
    mode: 'mark',
    type: BoldPlugin.key,
    match: '**',
  },
  {
    mode: 'mark',
    type: ItalicPlugin.key,
    match: '*',
  },
  {
    mode: 'mark',
    type: ItalicPlugin.key,
    match: '_',
  },
]

// MARK: – Helpers

function normalizeMarkdownLineBreaks(md: string) {
  return md.replace(/\r\n/g, '\n').replace(/\r/g, '\n')
}
