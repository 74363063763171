import {BooleanParam, StringParam, useQueryParam} from 'use-query-params'
import * as WebUI from '@cheddarup/web-ui'
import {useMemo} from 'react'
import config from 'src/config'
import {LinkButton} from 'src/components/LinkButton'
import {Logo} from 'src/components/Logo'
import {Link} from 'src/components/Link'
import {fetchAndSave} from 'src/helpers/api-helpers'
import {useIsAuthed} from 'src/hooks/useAuthToken'
import CardTabPencilIcon from 'src/images/CardTabPencilIcon.svg'
import CardCircleIcon from 'src/images/CardCircleIcon.svg'
import ProfileCirclesIcon from 'src/images/ProfileCirclesIcon.svg'
import PhoneCardIcon from 'src/images/PhoneCardIcon.svg'
import FormAndSignupIcon from 'src/images/FormAndSignupIcon.svg'

import usePublicCollection from './hooks/usePublicCollection'

const CheckoutThankYouPage = () => {
  const [addPayment] = useQueryParam('add-payment', BooleanParam)
  const [calendarButtonVisible] = useQueryParam('add-to-calendar', BooleanParam)
  const [paymentUuid] = useQueryParam('payment-uuid', StringParam)
  const [payerName] = useQueryParam('payer-name', StringParam)
  const [payerEmail] = useQueryParam('payere', StringParam)
  const media = WebUI.useMedia()
  const {publicCollection} = usePublicCollection()
  const isLoggedIn = useIsAuthed()

  const decodedPayerEmail = useMemo(
    () => atob(atob((payerEmail ?? '') as string)),
    [payerEmail],
  )

  return (
    <WebUI.VStack className="min-h-full min-w-0 bg-natural-100">
      <WebUI.HStack className="justify-between gap-3 px-4 py-12 sm:self-stretch sm:py-12 sm:pr-32 sm:pl-12">
        <WebUI.Anchor
          href={config.links.marketingPage}
          rel="noopener noreferrer"
        >
          <Logo
            className={
              '[&_>_.LogoIcon-graphics]:h-[27px] sm:[&_>_.LogoIcon-graphics]:h-9 [&_>_.LogoIcon-text]:h-[17.25px] sm:[&_>_.LogoIcon-text]:h-[23px]'
            }
            forceDisplayText
          />
        </WebUI.Anchor>

        <WebUI.HStack>
          <LinkButton className="uppercase" preserveSearch to="help">
            Help
          </LinkButton>
          <LinkButton className="uppercase" preserveSearch to="share">
            Share
          </LinkButton>
        </WebUI.HStack>
      </WebUI.HStack>

      <WebUI.VStack
        className={WebUI.cn(
          'grow items-center pt-16',
          addPayment ? 'gap-8' : 'gap-16',
        )}
      >
        <WebUI.VStack className="gap-4 px-8 text-center">
          <WebUI.Heading className="font-accentAlt text-ds-xl sm:text-ds-3xl">
            {addPayment
              ? 'Order recorded'
              : `Thank you${payerName ? `, ${payerName}` : ''}!`}
          </WebUI.Heading>
          <WebUI.Text className="font-light text-ds-lg">
            {payerEmail ? (
              <>
                You will receive an email confirmation shortly at{' '}
                <span className="font-bold">{decodedPayerEmail}</span>
              </>
            ) : (
              'An email confirmation will be sent shortly.'
            )}
            <br />
            {addPayment ? null : (publicCollection.groupPage as any)?.shared ? (
              <Link
                className="whitespace-normal"
                variant="primary"
                to={`/me/${publicCollection.organizer.groupPage}`}
              >
                View other collections by{' '}
                {publicCollection.organizer.displayName}
              </Link>
            ) : (
              <Link
                className="[&_>_.Anchor-content]:whitespace-normal [&_>_.Anchor-content]:font-light"
                variant="primary"
                to="help"
              >
                Questions about your order?
              </Link>
            )}
            <br />
          </WebUI.Text>
          {!isLoggedIn && (
            <LinkButton
              className="self-center rounded-xl [&_>_.Button-content]:font-bold"
              variant="default"
              to={`/guest?email=${decodedPayerEmail ?? ''}`}
            >
              View Order History
            </LinkButton>
          )}
          {calendarButtonVisible && !!paymentUuid && (
            <WebUI.Button
              className="self-center [&_>_.Button-content]:font-bold [&_>_.Button-content]:text-ds-sm"
              variant="link"
              iconBefore={<WebUI.PhosphorIcon icon="calendar-blank-bold" />}
              onClick={() =>
                fetchAndSave({
                  url: `users/payments/add_to_calendar?payment_uuid=${paymentUuid}`,
                  fileName: `${publicCollection.name}-events.ics`,
                })
              }
            >
              Add to Calendar
            </WebUI.Button>
          )}
        </WebUI.VStack>

        {addPayment ? (
          <LinkButton
            className={
              'h-[3.125rem] self-center rounded-[28px] px-8 text-ds-md [&_>_.Button-content]:font-bold'
            }
            variant="primary"
            to={`/collection/${publicCollection.id}/manage`}
          >
            Back to Collection Manager
          </LinkButton>
        ) : (
          <WebUI.VStack className="shrink-0 grow basis-auto gap-9 self-stretch bg-teal-80 p-14 sm:p-16">
            <WebUI.Text className="text-center font-accentAlt text-ds-md sm:text-ds-xl">
              Cheddar Up is the go-to for groups
            </WebUI.Text>

            {media.sm ? (
              <div
                className={
                  'flex w-full max-w-[720px] flex-row justify-between gap-4 self-center [&_>_.Stack]:shrink-0 [&_>_.Stack]:grow [&_>_.Stack]:basis-[0%] [&_>_.Stack]:items-center [&_>_.Stack]:justify-center [&_>_.Stack_>_.Text]:font-bold [&_>_.Stack_>_.Text]:text-ds-sm [&_>_.Stack_>_div]:flex [&_>_.Stack_>_div]:h-[100px] [&_>_.Stack_>_div]:items-center [&_>_.Stack_>_div]:justify-center'
                }
              >
                <WebUI.VStack className="gap-2">
                  <div>
                    <img className="h-[100px]" src={CardCircleIcon} alt="" />
                  </div>
                  <WebUI.Text>Frictionless Payments</WebUI.Text>
                </WebUI.VStack>
                <WebUI.VStack className="gap-2">
                  <div>
                    <img className="h-[62px]" src={ProfileCirclesIcon} alt="" />
                  </div>
                  <WebUI.Text>Instant Tracking</WebUI.Text>
                </WebUI.VStack>
                <WebUI.VStack className="gap-2">
                  <div>
                    <img className="h-18" src={FormAndSignupIcon} alt="" />
                  </div>
                  <WebUI.Text>Forms + Sign Ups</WebUI.Text>
                </WebUI.VStack>
                <WebUI.VStack className="gap-2">
                  <div>
                    <img className="h-17" src={PhoneCardIcon} alt="" />
                  </div>
                  <WebUI.Text>Point of Sale</WebUI.Text>
                </WebUI.VStack>
              </div>
            ) : (
              <div className="flex flex-row gap-6 self-center">
                <img className="h-[100px]" src={CardTabPencilIcon} alt="" />
                <ul className="list-disc font-normal text-ds-sm">
                  <li>Frictionless Payments</li>
                  <li>Instant Tracking</li>
                  <li>Customer Forms</li>
                  <li>Point of Sale</li>
                </ul>
              </div>
            )}

            <WebUI.Button
              className={
                'h-[3.125rem] self-center rounded-[28px] px-8 text-ds-md [&_>_.Button-content]:font-bold'
              }
              variant="primary"
              as={WebUI.Anchor}
              href={config.links.marketingPage}
              rel="noopener noreferrer"
            >
              FREE for your group
            </WebUI.Button>
          </WebUI.VStack>
        )}
      </WebUI.VStack>

      <WebUI.IconButton
        className="fixed top-4 right-4 text-8xl hover:text-gray800 sm:top-10 sm:right-10"
        size="default_alt"
        {...((publicCollection.groupPage as any)?.shared ||
        (addPayment && publicCollection.userManagesCollection)
          ? ({
              as: Link,
              to: (publicCollection.groupPage as any)?.shared
                ? `/me/${publicCollection.organizer.groupPage}`
                : `/collection/${publicCollection.id}/manage`,
            } as any)
          : ({
              as: WebUI.Anchor,
              rel: 'noopener noreferrer',
              href: config.links.marketingPage,
            } as any))}
      >
        <WebUI.PhosphorIcon icon="x-bold" />
      </WebUI.IconButton>
    </WebUI.VStack>
  )
}

export default CheckoutThankYouPage
