import {
  useDeleteGroupPageSectionMutation,
  useUpdateGroupPageSectionMutation,
} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'
import GroupPageSectionCard from './GroupPageSectionCard'
import GroupPageSpotlightItemCard from './GroupPageSpotlightItemCard'
import {SharpImage} from 'src/components/SharpImage'
import * as Util from '@cheddarup/util'
import {getBrandKitColorKey, getBrandKitColors} from '@cheddarup/core'
import {useDebounceCallback} from '@cheddarup/react-util'
import React, {useMemo, useRef, useState} from 'react'
import {useUserSlug} from 'src/components/ManageRoleProvider'
import {NextLink} from 'src/components/__next/Link'
import {timePeriodToLabelMap} from 'src/views/MePage/components/TotalCollected'

export interface GroupPageSectionsListProps
  extends React.ComponentPropsWithoutRef<'div'> {
  groupPage: Api.NewGroupPage
}

const GroupPageSectionsList: React.FC<GroupPageSectionsListProps> = ({
  groupPage,
  className,
  ...restProps
}) => {
  const [sectionToDelete, setSectionToDelete] =
    useState<WebUI.UniqueIdentifier>('')
  const userSlug = useUserSlug()
  const deleteGroupPageSectionAlertRef = useRef<WebUI.DialogInstance>(null)
  const updateGroupPageSectionMutation = useUpdateGroupPageSectionMutation()

  const sections: Api.GroupPageSection[] = useMemo(
    () =>
      Util.sort(
        Util.keys(groupPage.sections)
          .map((sectionName) => ({
            ...groupPage.sections[sectionName],
            id: sectionName,
          }))
          .filter((section) => section != null),
      ).asc((section) => section.position),
    [groupPage.sections],
  )

  const topBanner = sections.find((section) => section.type === 'TopBanner')
  const hasMoreSections = sections.some(
    (section) => section.id !== topBanner?.id,
  )

  const brandKitColors = getBrandKitColors(
    groupPage.branding?.color_palette.groupPage,
  )

  const debouncedUpdateGroupPageSection = useDebounceCallback(
    updateGroupPageSectionMutation.mutate,
    1000,
    true,
  )

  return (
    <>
      <WebUI.DragAndDrop
        onDragEnd={(event) => {
          if (!event.over) {
            return
          }

          const oldOrder = event.over.data.current?.sortable.items ?? []
          const newOrder = WebUI.arrayMoveByValue(
            oldOrder,
            event.active.id,
            event.over.id,
          )
          const sectionName = event.active.id
          const sectionNewPos = newOrder.indexOf(sectionName) + 1
          updateGroupPageSectionMutation.mutate({
            pathParams: {sectionName},
            body: {
              position: sectionNewPos,
              userSlug,
            },
          })
        }}
      >
        <div
          className={WebUI.cn('flex flex-col gap-5', className)}
          {...restProps}
        >
          {!!topBanner && (
            <>
              <GroupPageSectionCard
                id={topBanner.id.toString()}
                dragHandleVisible={false}
                sectionName="Top Banner"
                editPath="top-banner"
                colorPickerText="Select a color for your headline block:"
                defaultColor={brandKitColors[topBanner.background_color]}
                palette={[
                  brandKitColors.primary,
                  brandKitColors.secondary,
                  brandKitColors.neutral,
                  brandKitColors.white,
                ]}
                onColorChange={(newColor) =>
                  debouncedUpdateGroupPageSection({
                    pathParams: {sectionName: 'top_banner'},
                    body: {
                      background_color: getBrandKitColorKey(
                        brandKitColors,
                        newColor,
                      ),
                      userSlug,
                    },
                  })
                }
                onDelete={setSectionToDelete}
              >
                <div
                  className="-mx-4 sm:-mx-8 -my-4 flex aspect-[3/1] flex-col justify-center bg-center bg-cover p-8 sm:px-11 sm:py-15"
                  style={{
                    backgroundImage: topBanner.background_image
                      ? `url(${topBanner.background_image.edited_image_url ?? topBanner.background_image.url})`
                      : undefined,
                    backgroundColor: brandKitColors[topBanner.background_color],
                  }}
                >
                  {(topBanner.headline || topBanner.logo) && (
                    <div
                      className="flex max-w-[440px] flex-col items-center gap-5 rounded-default p-10 pt-8 sm:flex-row"
                      style={{
                        backgroundColor:
                          brandKitColors[topBanner.background_color],
                      }}
                    >
                      {topBanner.logo && (
                        <SharpImage
                          className="shrink-0 rounded-extended"
                          alt=""
                          image={topBanner.logo}
                          height={98}
                          width={104}
                        />
                      )}
                      <div className="flex flex-col gap-4 text-natural-100">
                        <WebUI.Heading
                          as="h3"
                          className="font-accentAlt leading-compact"
                          style={{
                            color: Util.getReadableColor(
                              brandKitColors[topBanner.background_color],
                            ),
                          }}
                        >
                          {topBanner.headline}
                        </WebUI.Heading>
                        {!!topBanner.tagline && (
                          <WebUI.Text
                            className="text-ds-sm"
                            style={{
                              color: Util.getReadableColor(
                                brandKitColors[topBanner.background_color],
                              ),
                            }}
                          >
                            {topBanner.tagline}
                          </WebUI.Text>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </GroupPageSectionCard>
              <WebUI.Separator />
            </>
          )}
          {hasMoreSections && (
            <WebUI.Text className="font-light text-ds-sm">
              Sections can be rearranged by dragging and dropping.{' '}
              <WebUI.Anchor
                className="[&_>_.Text]:font-light"
                href={userSlug ? `/me/${userSlug}` : undefined}
                target={groupPage.status === 'published' ? '_blank' : ''}
                rel="noopener noreferrer"
              >
                Preview your page to view all content and images.
              </WebUI.Anchor>
            </WebUI.Text>
          )}
          <WebUI.SortableContext
            items={sections}
            strategy={WebUI.rectSortingStrategy}
          >
            {({items}) =>
              items.map((sId) => {
                const section = sections.find((s) => s.id === sId)

                if (!section) {
                  return null
                }

                switch (section.type) {
                  case 'CollectionSection':
                    return (
                      <GroupPageSectionCard
                        key={sId}
                        id={sId}
                        deletable={groupPage.status === 'draft'}
                        sectionName="Collections"
                        editPath="add-collections"
                        colorPickerText="Select a neutral background color:"
                        defaultColor={brandKitColors[section.background_color]}
                        palette={[brandKitColors.neutral, brandKitColors.white]}
                        onColorChange={(newColor) =>
                          debouncedUpdateGroupPageSection({
                            pathParams: {sectionName: 'collection_section'},
                            body: {
                              background_color: getBrandKitColorKey(
                                brandKitColors,
                                newColor,
                              ),
                              userSlug,
                            },
                          })
                        }
                        onDelete={setSectionToDelete}
                      >
                        <div className="flex flex-col gap-2">
                          <WebUI.Text className="font-bold">
                            {section.headline}
                          </WebUI.Text>
                          <WebUI.Text className="font-light text-ds-sm">
                            {section.description}
                          </WebUI.Text>
                        </div>
                        <NextLink
                          className="font-normal text-ds-sm"
                          to="add-collections"
                        >
                          View included Collections
                        </NextLink>
                      </GroupPageSectionCard>
                    )
                  case 'CollectionSpotlight':
                    return (
                      <GroupPageSectionCard
                        key={sId}
                        id={sId}
                        sectionName="Collection Spotlights"
                        editPath="collection-spotlight"
                        defaultColor={brandKitColors[section.background_color]}
                        colorPickerText="Select a background color:"
                        palette={[
                          brandKitColors.primary,
                          brandKitColors.secondary,
                          brandKitColors.neutral,
                          brandKitColors.white,
                        ]}
                        onColorChange={(newColor) =>
                          debouncedUpdateGroupPageSection({
                            pathParams: {sectionName: 'collection_spotlight'},
                            body: {
                              background_color: getBrandKitColorKey(
                                brandKitColors,
                                newColor,
                              ),
                              userSlug,
                            },
                          })
                        }
                        onDelete={setSectionToDelete}
                      >
                        <div className="flex flex-row flex-wrap gap-4">
                          {section.spotlight_items?.slice(0, 3).map((item) => (
                            <GroupPageSpotlightItemCard
                              key={item.id}
                              item={item}
                            />
                          ))}
                        </div>
                      </GroupPageSectionCard>
                    )
                  case 'TotalCollected':
                    return (
                      <GroupPageSectionCard
                        key={sId}
                        id={sId}
                        sectionName="Total Collected"
                        editPath="total-collected"
                        colorPickerText="Select a background color:"
                        defaultColor={brandKitColors[section.background_color]}
                        palette={[
                          brandKitColors.primary,
                          brandKitColors.secondary,
                          brandKitColors.neutral,
                          brandKitColors.white,
                        ]}
                        onColorChange={(newColor) =>
                          debouncedUpdateGroupPageSection({
                            pathParams: {sectionName: 'total_collected'},
                            body: {
                              background_color: getBrandKitColorKey(
                                brandKitColors,
                                newColor,
                              ),
                              userSlug,
                            },
                          })
                        }
                        onDelete={setSectionToDelete}
                      >
                        <div className="flex flex-col gap-3">
                          <WebUI.Text className="font-bold">
                            {section.headline}
                          </WebUI.Text>
                          <WebUI.Text className="font-light text-ds-sm">
                            {section.description}
                          </WebUI.Text>
                        </div>
                        {section.details?.timePeriod && (
                          <WebUI.Text className="font-extrabold text-ds-lg opacity-50">
                            {`$ ${timePeriodToLabelMap[section.details.timePeriod]} Total`}
                          </WebUI.Text>
                        )}
                      </GroupPageSectionCard>
                    )
                  case 'AboutUsSection':
                    return (
                      <GroupPageSectionCard
                        key={sId}
                        id={sId}
                        sectionName="About Us"
                        editPath="about-us"
                        colorPickerText="Select a neutral background color:"
                        defaultColor={brandKitColors[section.background_color]}
                        palette={[brandKitColors.neutral, brandKitColors.white]}
                        onColorChange={(newColor) =>
                          debouncedUpdateGroupPageSection({
                            pathParams: {sectionName: 'about_us'},
                            body: {
                              background_color: getBrandKitColorKey(
                                brandKitColors,
                                newColor,
                              ),
                              userSlug,
                            },
                          })
                        }
                        onDelete={setSectionToDelete}
                      >
                        <div className="flex flex-col gap-3">
                          <WebUI.Text className="font-bold">
                            {section.headline}
                          </WebUI.Text>
                          <WebUI.MarkdownParagraph
                            className="line-clamp-2 text-ds-sm [&_h2]:text-ds-md"
                            markdown={section.description ?? ''}
                          />
                        </div>
                      </GroupPageSectionCard>
                    )
                  case 'MeetTheTeam':
                    return (
                      <GroupPageSectionCard
                        key={sId}
                        id={sId}
                        sectionName="Meet the Team"
                        editPath="team"
                        colorPickerText="Select a neutral background color:"
                        defaultColor={brandKitColors[section.background_color]}
                        palette={[brandKitColors.neutral, brandKitColors.white]}
                        onColorChange={(newColor) =>
                          debouncedUpdateGroupPageSection({
                            pathParams: {sectionName: 'meet_the_team'},
                            body: {
                              background_color: getBrandKitColorKey(
                                brandKitColors,
                                newColor,
                              ),
                              userSlug,
                            },
                          })
                        }
                        onDelete={setSectionToDelete}
                      >
                        <div className="flex flex-col gap-3">
                          <WebUI.Text className="font-bold">
                            {section.headline}
                          </WebUI.Text>
                          <WebUI.Text className="line-clamp-2 font-light text-ds-sm">
                            {section.description}
                          </WebUI.Text>
                        </div>
                        <NextLink className="font-normal text-ds-sm" to="team">
                          View included team members
                        </NextLink>
                      </GroupPageSectionCard>
                    )
                  case 'Sponsor':
                    return (
                      <GroupPageSectionCard
                        key={sId}
                        id={sId}
                        sectionName="Sponsors"
                        editPath="sponsors"
                        colorPickerText="Select a background color:"
                        defaultColor={brandKitColors[section.background_color]}
                        palette={[
                          brandKitColors.primary,
                          brandKitColors.secondary,
                          brandKitColors.neutral,
                          brandKitColors.white,
                        ]}
                        onColorChange={(newColor) =>
                          debouncedUpdateGroupPageSection({
                            pathParams: {sectionName: 'sponsor'},
                            body: {
                              background_color: getBrandKitColorKey(
                                brandKitColors,
                                newColor,
                              ),
                              userSlug,
                            },
                          })
                        }
                        onDelete={setSectionToDelete}
                      >
                        <div className="flex flex-col gap-3">
                          <WebUI.Text className="font-bold">
                            {section.headline}
                          </WebUI.Text>
                          <WebUI.Text className="line-clamp-2 font-light text-ds-sm">
                            {section.description}
                          </WebUI.Text>
                        </div>
                        <NextLink
                          className="font-normal text-ds-sm"
                          to="sponsors"
                        >
                          View included sponsors
                        </NextLink>
                      </GroupPageSectionCard>
                    )
                  default:
                    return null
                }
              })
            }
          </WebUI.SortableContext>
        </div>
      </WebUI.DragAndDrop>
      <DeleteGroupPageSectionAlert
        ref={deleteGroupPageSectionAlertRef}
        sectionId={sectionToDelete}
        visible={!!sectionToDelete}
        onDidHide={() => setSectionToDelete('')}
      />
    </>
  )
}

// MARK: – DeleteGroupPageSectionAlert

interface DeleteGroupPageSectionAlertProps extends WebUI.AlertProps {
  sectionId: WebUI.UniqueIdentifier
}

const DeleteGroupPageSectionAlert = React.forwardRef<
  WebUI.DialogInstance,
  DeleteGroupPageSectionAlertProps
>(({sectionId, ...restProps}, forwardedRef) => {
  const deleteGroupPageSectionMutation = useDeleteGroupPageSectionMutation()

  return (
    <WebUI.Alert
      aria-label="Delete group page section confirmation"
      ref={forwardedRef}
      {...restProps}
    >
      {(dialog) => (
        <>
          <WebUI.AlertHeader>Are you sure?</WebUI.AlertHeader>
          <WebUI.AlertContentView
            text="This action cannot be undone. Do you wish to proceed?"
            actions={
              <>
                <WebUI.AlertActionButton
                  loading={deleteGroupPageSectionMutation.isPending}
                  execute={async () => {
                    await deleteGroupPageSectionMutation.mutateAsync({
                      pathParams: {sectionName: sectionId},
                    })
                    dialog.hide()
                  }}
                >
                  Delete
                </WebUI.AlertActionButton>
                <WebUI.AlertCancelButton />
              </>
            }
          />
        </>
      )}
    </WebUI.Alert>
  )
})

export default GroupPageSectionsList
