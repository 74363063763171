import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {
  posUserSchema,
  tabDetailedSchema,
  tabFaqSchema,
  tabListMemberSchema,
  tabSchema,
} from '../schemas'

export default {
  list: makeEndpoint({
    path: 'users/tabs',
    queryParamsSchema: z
      .object({
        filter: z.enum(['contacts', 'history']),
      })
      .optional(),
    responseSchema: tabSchema.array(),
  }),
  detail: makeEndpoint({
    path: 'users/tabs/:tabId',
    responseSchema: tabDetailedSchema,
  }),
  byPOSCode: makeEndpoint({
    path: 'users/tabs/by_pos_code',
    queryParamsSchema: z.object({
      code: z.string(),
      collectorEmail: z.string(),
    }),
    responseSchema: tabDetailedSchema,
  }),
  posReport: makeEndpoint({
    path: 'users/tabs/:tabId/pos_report',
    responseSchema: posUserSchema.array(),
  }),
  listMembers: makeEndpoint({
    path: 'users/tabs/:tabId/list_members',
    responseSchema: tabListMemberSchema.array(),
  }),
  create: makeEndpoint({
    path: 'users/tabs',
    method: 'POST',
    bodySchema: tabSchema
      .extend({
        folder_id: z.number().nullish(),
        owner: z.number().nullish(),
        logo_id: z.number().nullish(),
        do_not_publish: z.boolean().optional(),
        imageCarouselIds: z.number().array(),
        featuredImageId: z.number(),
      })
      .deepPartial(),
    responseSchema: tabDetailedSchema,
  }),
  update: makeEndpoint({
    path: 'users/tabs/:tabId',
    method: 'PATCH',
    bodySchema: tabSchema
      .extend({
        folder_id: z.number().nullish(),
        owner: z.number().nullish(),
        logo_id: z.number().nullish(),
        imageCarouselIds: z.number().array(),
        featuredImageId: z.number(),
      })
      .deepPartial(),
    responseSchema: tabDetailedSchema,
  }),
  delete: makeEndpoint({
    path: 'users/tabs/:tabId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
  updateBatch: makeEndpoint({
    path: 'users/tabs/modify',
    method: 'POST',
    bodySchema: z.object({
      collection_ids: z.number().array(),
      options: z
        .object({
          groupPage: z
            .object({
              shared: z.boolean(),
              categoryId: z.string().nullable(),
            })
            .partial(),
        })
        .partial(),
    }),
    responseSchema: tabDetailedSchema,
  }),
  deleteBatch: makeEndpoint({
    path: 'users/tabs/delete',
    method: 'POST',
    bodySchema: z.object({
      collection_ids: z.number().array(),
    }),
    responseSchema: z.any(),
  }),
  moveBatch: makeEndpoint({
    path: 'users/tabs/move',
    method: 'POST',
    bodySchema: z.object({
      collection_ids: z.number().array(),
      folder_id: z.number().nullable(),
    }),
    responseSchema: tabSchema.array(),
  }),
  replicate: makeEndpoint({
    path: 'users/tabs/:tabId/replicate',
    method: 'PATCH',
    bodySchema: tabSchema.partial(),
    responseSchema: tabDetailedSchema,
  }),
  close: makeEndpoint({
    path: 'users/tabs/:tabId/close',
    method: 'PATCH',
    responseSchema: z.any(),
  }),
  closeBatch: makeEndpoint({
    path: 'users/tabs/close',
    method: 'POST',
    bodySchema: z.object({
      collection_ids: z.number().array(),
    }),
    responseSchema: z.any(),
  }),
  setPOSCode: makeEndpoint({
    path: 'users/tabs/:tabId/set_pos_code',
    method: 'PATCH',
    responseSchema: z.any(),
  }),
  sortObjects: makeEndpoint({
    path: 'users/tabs/:tabId/sort',
    method: 'PATCH',
    bodySchema: z.object({
      type: z.enum(['categories', 'forms', 'items', 'signups']),
      parent_id: z.number().nullish(),
      order: z.number().array(),
    }),
    responseSchema: z.any(),
  }),
  publish: makeEndpoint({
    path: 'users/tabs/:tabId/publish_tab',
    method: 'PATCH',
    responseSchema: tabDetailedSchema,
  }),
  listFaqs: makeEndpoint({
    path: 'users/tabs/:tabId/faqs',
    responseSchema: tabFaqSchema.array(),
  }),
  createFaq: makeEndpoint({
    path: 'users/tabs/:tabId/faqs',
    method: 'POST',
    bodySchema: z.object({
      question: z.string(),
      answer: z.string(),
      position: z.number(),
    }),
    responseSchema: tabFaqSchema,
  }),
  updateFaq: makeEndpoint({
    path: 'users/tabs/:tabId/faqs/:faqId',
    method: 'PUT',
    bodySchema: z.object({
      question: z.string(),
      answer: z.string(),
      position: z.number(),
    }),
    responseSchema: tabFaqSchema,
  }),
  deleteFaq: makeEndpoint({
    path: 'users/tabs/:tabId/faqs/:faqId',
    method: 'DELETE',
    responseSchema: z.void(),
  }),
}
