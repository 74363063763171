import {useParams} from 'react-router-dom'
import React, {useMemo, useRef, useState} from 'react'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {api, useSortSpotsMutation} from '@cheddarup/api-client'
import {useLiveRef, useUpdateEffect} from '@cheddarup/react-util'
import {QueryDataList, QueryDataSectionList} from 'src/components/QueryDataList'

import {SpotFormModal} from './SpotFormModal'
import {SpotDeleteAlert, SpotQuickActionGroup} from './components'
import type {SignUpFormik} from './SignUpFormPage'
import {getLocalTimeZone, isSameDay} from '@internationalized/date'

export interface SignUpSpotListProps
  extends React.ComponentPropsWithoutRef<'div'> {
  signUp: Api.SignUp | null
  formik: SignUpFormik
}

export const SignUpSpotList: React.FC<SignUpSpotListProps> = ({
  signUp,
  formik,
  className,
  ...restProps
}) => {
  const urlParams = useParams()
  const [spotToEdit, setSpotToEdit] = useState<Api.SignUpSpot | null>(null)
  const [spotToDelete, setSpotToDelete] = useState<Api.SignUpSpot | null>(null)
  const spotFormModalRef = useRef<WebUI.DialogInstance>(null)
  const isSpotListEmptyQuery = api.tabSignupSpots.list.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
        // biome-ignore lint/style/noNonNullAssertion:
        signupId: urlParams.signup!,
      },
    },
    {
      enabled: urlParams.signup != null,
      select: (spots) => spots.length === 0,
    },
  )

  const timeZone = signUp?.options.timeZone ?? getLocalTimeZone()

  useUpdateEffect(() => {
    if (spotToEdit) {
      spotFormModalRef.current?.show()
    }
  }, [spotToEdit])

  const ListComp =
    !signUp || signUp.options.signupType === 'list'
      ? SignUpListSpotList
      : {
          date: SignUpScheduleSpotByDateList,
          spot: SignUpScheduleSpotBySpotList,
        }[formik.values.groupedBy]

  return (
    <div className={WebUI.cn('flex flex-col', className)} {...restProps}>
      <ListComp
        className="mt-5 overflow-y-visible"
        timeZone={timeZone}
        onEdit={(spot) => setSpotToEdit(spot)}
        onDelete={(spot) => setSpotToDelete(spot)}
      />
      <div className="-order-1 sticky top-0 flex flex-col">
        <div className="flex flex-row justify-between gap-3 bg-natural-100 py-3">
          <WebUI.Button
            disabled={formik.isSubmitting}
            onClick={async () => {
              if (!signUp) {
                const errors = await formik.validateForm()

                if (Object.keys(errors).length > 0) {
                  return
                }

                await formik.submitForm()
              }

              spotFormModalRef.current?.show()
            }}
          >
            Add Spot
          </WebUI.Button>
          {signUp?.options.signupType === 'schedule' && (
            <WebUI.DropdownSelect<Api.SignUpGroupedBy>
              size="compact"
              value={formik.values.groupedBy}
              onValueChange={(newGroupBy) => {
                if (newGroupBy) {
                  formik.setFieldValue('groupedBy', newGroupBy)
                }
              }}
            >
              <WebUI.DropdownSelectOption value="date">
                Display by Date
              </WebUI.DropdownSelectOption>
              <WebUI.DropdownSelectOption value="spot">
                Display by Spot
              </WebUI.DropdownSelectOption>
            </WebUI.DropdownSelect>
          )}
        </div>
        {isSpotListEmptyQuery.data === false && (
          <WebUI.Separator className="-mx-[999px]" variant="primary" />
        )}
      </div>

      <SpotDeleteAlert
        visible={!!spotToDelete}
        spot={spotToDelete}
        onDidHide={() => setSpotToDelete(null)}
        signupType={signUp?.options.signupType}
      />
      <SpotFormModal
        ref={spotFormModalRef}
        signUp={signUp}
        spot={spotToEdit}
        onDidHide={() => setSpotToEdit(null)}
      />
    </div>
  )
}

interface SignUpListSpotListProps {
  className?: string
  onEdit: (spot: Api.SignUpSpot) => void
  onDelete: (spot: Api.SignUpSpot) => void
}

const SignUpListSpotList: React.FC<SignUpListSpotListProps> = ({
  onEdit,
  onDelete,
  className,
  ...restProps
}) => {
  const urlParams = useParams()
  const onEditRef = useLiveRef(onEdit)
  const onDeleteRef = useLiveRef(onDelete)
  const spotsListQuery = api.tabSignupSpots.list.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
        // biome-ignore lint/style/noNonNullAssertion:
        signupId: urlParams.signup!,
      },
    },
    {
      enabled: urlParams.signup != null,
      select: (spots) => Util.sort(spots).asc((s) => s.position),
    },
  )
  const sortSpotsMutation = useSortSpotsMutation()

  const SignUpListSpotListRowWithHandlers: WebUI.DataRowComponentType<Api.SignUpSpot> =
    useMemo(
      () =>
        React.forwardRef((rowProps, forwardedRef) => (
          <SignUpListSpotListRow
            ref={forwardedRef}
            // biome-ignore lint/style/noNonNullAssertion:
            onEdit={() => onEditRef.current(rowProps.row!.original)}
            // biome-ignore lint/style/noNonNullAssertion:
            onDelete={() => onDeleteRef.current(rowProps.row!.original)}
            {...rowProps}
          />
        )),
      [],
    )

  return (
    <QueryDataList
      className={WebUI.cn('gap-5', className)}
      query={spotsListQuery}
      initialState={{focusedItemId: null, selectedItemIdsMap: {}}}
      ListComponent={WebUI.SortableList}
      DataRowComponent={SignUpListSpotListRowWithHandlers}
      EmptyStateViewComponent={SpotEmptyStateView}
      onOrderChange={(newOrder) =>
        sortSpotsMutation.mutate({
          pathParams: {
            // biome-ignore lint/style/noNonNullAssertion:
            tabId: urlParams.collection!,
            // biome-ignore lint/style/noNonNullAssertion:
            signupId: urlParams.signup!,
          },
          body: {
            order: newOrder.map(Number),
          },
        })
      }
      {...restProps}
    />
  )
}

// MARK: – SignUpScheduleSpotBySpotList

interface SignUpScheduleSpotBySpotListSectionData extends Api.SignUpSpot {}
interface SignUpScheduleSpotBySpotListRowData {
  id: string
  date: string
  timeZone: string
  spot: Api.SignUpSpot
  timeSlots: Api.TimeSlot[]
}

interface SignUpScheduleSpotBySpotListProps {
  className?: string
  timeZone: string
  onEdit: (spot: Api.SignUpSpot) => void
  onDelete: (spot: Api.SignUpSpot) => void
}

function SignUpScheduleSpotBySpotList({
  onEdit,
  onDelete,
  timeZone,
  ...restProps
}: SignUpScheduleSpotBySpotListProps) {
  const urlParams = useParams()
  const onEditRef = useLiveRef(onEdit)
  const onDeleteRef = useLiveRef(onDelete)
  const spotsListQuery = api.tabSignupSpots.list.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
        // biome-ignore lint/style/noNonNullAssertion:
        signupId: urlParams.signup!,
      },
    },
    {
      enabled: urlParams.signup != null,
      select: (spots) =>
        Util.sort(
          spots.map((spot) => ({
            sectionData: spot,
            rowsData: Util.sort(
              spot.time_slots
                .filter((ts) => ts.options.startTime != null)
                .reduce<SignUpScheduleSpotBySpotListRowData[]>(
                  (acc, currTs, _idx, tss) => {
                    if (
                      acc.some((r) =>
                        r.id
                          .split('-')
                          .slice(1)
                          .map(Number)
                          .includes(currTs.id),
                      )
                    ) {
                      return acc
                    }

                    const currTsDateTime = Util.parseDateTime(
                      // biome-ignore lint/style/noNonNullAssertion:
                      currTs.options.startTime!,
                      timeZone,
                    )

                    const tssWithMatchingDay = tss.filter((ts) => {
                      const tsDateTime = Util.parseDateTime(
                        // biome-ignore lint/style/noNonNullAssertion:
                        ts.options.startTime!,
                        timeZone,
                      )

                      return (
                        tsDateTime &&
                        currTsDateTime &&
                        isSameDay(tsDateTime, currTsDateTime)
                      )
                    })

                    return [
                      ...acc,
                      {
                        id: `${spot.id}-${tssWithMatchingDay
                          .map((ts) => ts.id)
                          .join('-')}`,
                        date: currTsDateTime
                          ? Util.nativeFormatDate(
                              currTs.options.startTime ?? '',
                              {
                                weekday: 'short',
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                                timeZone,
                              },
                            )
                          : '',
                        timeZone,
                        spot,
                        timeSlots: tssWithMatchingDay,
                      },
                    ]
                  },
                  [],
                ),
            ).asc((r) =>
              r.timeSlots[0]?.options.startTime
                ? new Date(r.timeSlots[0].options.startTime)
                : -1,
            ),
          })),
        ).asc((s) => s.sectionData.position),
    },
  )
  const sortSpotsMutation = useSortSpotsMutation()

  const SignUpScheduleSpotListBySpotRowWithHandlers = useMemo(
    () =>
      React.forwardRef<
        HTMLDivElement,
        WebUI.DataRowComponentProps<SignUpScheduleSpotBySpotListRowData>
      >((rowProps, forwardedRef) => (
        <SignUpScheduleSpotListBySpotRow
          ref={forwardedRef}
          onEdit={(data) => onEditRef.current(data.spot)}
          {...rowProps}
        />
      )),
    [],
  )

  const SignUpScheduleSpotListBySpotSectionWithHandlers = useMemo(
    () =>
      React.forwardRef<
        HTMLDivElement,
        WebUI.DataSectionComponentProps<SignUpScheduleSpotBySpotListSectionData>
      >((sectionProps, forwardedRef) => (
        <SignUpScheduleSpotListBySpotSection
          ref={forwardedRef}
          // biome-ignore lint/style/noNonNullAssertion:
          onEdit={() => onEditRef.current(sectionProps.section!.original)}
          // biome-ignore lint/style/noNonNullAssertion:
          onDelete={() => onDeleteRef.current(sectionProps.section!.original)}
          {...sectionProps}
        />
      )),
    [],
  )

  return (
    <QueryDataSectionList
      className="gap-4 [&_.SectionContainer]:gap-5"
      estimateRowSize={64}
      query={spotsListQuery}
      SectionListComponent={WebUI.SortableSectionList}
      sortableBetweenSections={false}
      sortableRows={false}
      EmptyStateViewComponent={SpotEmptyStateView}
      DataSectionComponent={SignUpScheduleSpotListBySpotSectionWithHandlers}
      DataRowComponent={SignUpScheduleSpotListBySpotRowWithHandlers}
      onOrderChange={(newOrder) =>
        sortSpotsMutation.mutate({
          pathParams: {
            // biome-ignore lint/style/noNonNullAssertion:
            tabId: urlParams.collection!,
            // biome-ignore lint/style/noNonNullAssertion:
            signupId: urlParams.signup!,
          },
          body: {
            order: newOrder.orderedRowIds.map(Number),
          },
        })
      }
      {...restProps}
    />
  )
}

// MARK: – SignUpScheduleSpotByDateList

interface SignUpScheduleSpotByDateListSectionData {
  id: string
  date: string
  timeZone: string
}
interface SignUpScheduleSpotByDateListRowData
  extends Omit<Api.SignUpSpot, 'id'> {
  id: string
  spotId: number
  timeZone: string
}

interface SignUpScheduleSpotByDateListProps {
  className?: string
  timeZone: string
  onEdit: (spot: Api.SignUpSpot) => void
  onDelete: (spot: Api.SignUpSpot) => void
}

function SignUpScheduleSpotByDateList({
  timeZone,
  onEdit,
  onDelete,
  ...restProps
}: SignUpScheduleSpotByDateListProps) {
  const urlParams = useParams()
  const onEditRef = useLiveRef(onEdit)
  const onDeleteRef = useLiveRef(onDelete)
  const spotsListQuery = api.tabSignupSpots.list.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
        // biome-ignore lint/style/noNonNullAssertion:
        signupId: urlParams.signup!,
      },
    },
    {
      enabled: urlParams.signup != null,
      select: (
        _spots,
      ): Array<
        WebUI.SectionListData<
          SignUpScheduleSpotByDateListSectionData,
          SignUpScheduleSpotByDateListRowData
        >
      > => {
        const spots = Util.sort(_spots).asc((s) => s.position)

        const timeSlots = Util.sort(
          spots
            .flatMap((s) =>
              s.time_slots.map((ts) => ({
                ...ts,
                spotId: s.id,
              })),
            )
            .filter((ts) => !!ts.options.startTime),
        ).asc((ts) => new Date(ts.options.startTime ?? ''))
        const timeSlotsByStartTime = Util.groupBy(timeSlots, (s) =>
          Util.nativeFormatDate(
            Util.parseDateTime(s.options.startTime ?? '', timeZone)
              ?.toDate()
              .toLocaleDateString('en-US', {timeZone}) ?? '',
            {
              weekday: 'short',
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              timeZone,
            },
          ),
        )

        return Util.arrayFromObject(
          timeSlotsByStartTime,
          (startTime, groupedTimeSlots) => ({
            sectionData: {
              id: startTime,
              date: startTime,
              timeZone,
            },
            rowsData: spots
              .map((s) => ({
                ...s,
                id: `${startTime}-${s.id}`,
                spotId: s.id,
                timeZone,
                time_slots: Util.sort(
                  groupedTimeSlots.filter((ts) => ts.spotId === s.id),
                ).asc((ts) =>
                  ts.options.startTime ? new Date(ts.options.startTime) : -1,
                ),
              }))
              .filter((s) => s.time_slots.length > 0),
          }),
        )
      },
    },
  )
  const sortSpotsMutation = useSortSpotsMutation()

  const RowWithHandlers: WebUI.DataRowComponentType<SignUpScheduleSpotByDateListRowData> =
    useMemo(
      () =>
        React.forwardRef((rowProps, forwardedRef) => (
          <SignUpScheduleSpotListByDateRow
            ref={forwardedRef}
            onEdit={() =>
              onEditRef.current({
                // biome-ignore lint/style/noNonNullAssertion:
                ...rowProps.row!.original,
                // biome-ignore lint/style/noNonNullAssertion:
                id: rowProps.row!.original.spotId,
              })
            }
            onDelete={() =>
              onDeleteRef.current({
                // biome-ignore lint/style/noNonNullAssertion:
                ...rowProps.row!.original,
                // biome-ignore lint/style/noNonNullAssertion:
                id: rowProps.row!.original.spotId,
              })
            }
            {...rowProps}
          />
        )),
      [],
    )

  const sortDisabledSectionIds = useMemo(
    () => spotsListQuery.data?.map((s) => s.sectionData.id),
    [spotsListQuery.data],
  )

  return (
    <QueryDataSectionList
      className="gap-4 [&_.SectionContainer]:gap-5"
      estimateRowSize={64}
      sortDisabledSectionIds={sortDisabledSectionIds}
      query={spotsListQuery}
      SectionListComponent={WebUI.SortableSectionList}
      sortableBetweenSections={false}
      EmptyStateViewComponent={SpotEmptyStateView}
      DataSectionComponent={SignUpScheduleSpotListByDateSection}
      DataRowComponent={RowWithHandlers}
      onOrderChange={(newOrder) =>
        sortSpotsMutation.mutate({
          pathParams: {
            // biome-ignore lint/style/noNonNullAssertion:
            tabId: urlParams.collection!,
            // biome-ignore lint/style/noNonNullAssertion:
            signupId: urlParams.signup!,
          },
          body: {
            order: newOrder.orderedRowIds.map((rId) =>
              typeof rId === 'string' ? Number(rId.split('-')[1]) : -1,
            ),
          },
        })
      }
      {...restProps}
    />
  )
}

// MARK: - SignUpListSpotListRow

interface SignUpListSpotListRowProps
  extends WebUI.DataRowComponentProps<Api.SignUpSpot> {
  onEdit: () => void
  onDelete: () => void
}

const SignUpListSpotListRow = React.forwardRef<
  HTMLDivElement,
  SignUpListSpotListRowProps
>(
  (
    {row, setSelected, onEdit, onDelete, className, ...restProps},
    forwardedRef,
  ) => {
    const spot = row?.original
    return (
      <WebUI.Card
        data-hidden={!!spot?.hidden}
        ref={forwardedRef}
        className={WebUI.cn(
          'flex min-h-[74px] flex-row items-center gap-6 p-5 [&[data-hidden=true]_>_*:not(.Card-accessoryView)]:opacity-60',
          className,
        )}
        dragHandleVisible
        accessoryView={
          !!spot && (
            <SpotQuickActionGroup
              spot={spot}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          )
        }
        {...restProps}
      >
        <div className="flex w-[240px] flex-auto flex-col gap-2 pl-4">
          {spot ? (
            <>
              <WebUI.Button variant="link" onClick={() => onEdit()}>
                {spot.name}
              </WebUI.Button>
              {!!spot.description &&
                Util.stripMarkdown(spot.description).replaceAll('\n', '')
                  .length > 0 && (
                  <WebUI.MarkdownParagraph
                    className="line-clamp-3 text-ds-xs"
                    markdown={spot.description}
                  />
                )}
            </>
          ) : (
            <>
              <WebUI.Skeleton width={120} />
              <WebUI.Skeleton width={180} height={10} />
            </>
          )}
        </div>

        <div className="flex shrink-0 grow basis-auto flex-row items-center justify-between gap-3">
          {spot ? (
            <div className="w-[126px] text-ds-sm">
              Available:{' '}
              {spot.time_slots.some((ts) => ts.available_quantity == null)
                ? 'Unlimited'
                : Util.sum(
                    spot.time_slots.map((ts) => ts.available_quantity ?? 0),
                  )}
            </div>
          ) : (
            <WebUI.Skeleton width={120} />
          )}
          {spot?.hidden ? (
            <WebUI.PhosphorIcon icon="eye-slash" width={32} />
          ) : (
            <div className="w-8" />
          )}
        </div>
      </WebUI.Card>
    )
  },
)

// MARK: - SpotScheduleSection

const SignUpScheduleSpotListByDateSection = React.forwardRef<
  HTMLDivElement,
  WebUI.DataSectionComponentProps<SignUpScheduleSpotByDateListSectionData>
>(({setSelected, section, children, ...restProps}, forwardedRef) => (
  <WebUI.Disclosure initialVisible {...restProps}>
    {(disclosure) => (
      <WebUI.Card ref={forwardedRef}>
        <div
          data-expanded={disclosure.visible}
          className={'flex flex-row p-6 data-[expanded=true]:border-b'}
        >
          <WebUI.DisclosureButton
            className="font-semibold [&_>_.Button-iconBefore]:mr-3"
            variant="text"
            iconBefore={
              <WebUI.PhosphorIcon
                className={
                  '-rotate-90 transition-transform duration-100 ease-linear [[aria-expanded=true]_>_.Button-iconBefore_>_&]:rotate-0'
                }
                icon="caret-down-fill"
              />
            }
          >
            {section?.original.date ?? ''}
          </WebUI.DisclosureButton>
        </div>
        <WebUI.DisclosureContent className="p-4">
          {children}
        </WebUI.DisclosureContent>
      </WebUI.Card>
    )}
  </WebUI.Disclosure>
))

// MARK: - SignUpScheduleSpotListByDateRow

interface SignUpScheduleSpotListByDateRowProps
  extends WebUI.DataRowComponentProps<SignUpScheduleSpotByDateListRowData> {
  onEdit: () => void
  onDelete: () => void
}

const SignUpScheduleSpotListByDateRow = React.forwardRef<
  HTMLDivElement,
  SignUpScheduleSpotListByDateRowProps
>(
  (
    {row, setSelected, onEdit, onDelete, className, ...restProps},
    forwardedRef,
  ) => {
    const spot = useMemo(
      () =>
        row
          ? {
              ...row.original,
              id: row.original.spotId,
            }
          : null,
      [row],
    )

    return (
      <WebUI.Card
        data-hidden={!!spot?.hidden}
        ref={forwardedRef}
        className={WebUI.cn(
          'flex flex-col items-stretch justify-center gap-6 p-5 sm:flex-row sm:items-center sm:justify-start [&[data-hidden=true]_>_*:not(.Card-accessoryView)]:opacity-60',
          className,
        )}
        accessoryView={
          !!spot && (
            <SpotQuickActionGroup
              spot={spot}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          )
        }
        dragHandleVisible
        {...restProps}
      >
        <div className="flex w-[240px] flex-col gap-2">
          <WebUI.Button variant="link" onClick={onEdit}>
            {spot?.name}
          </WebUI.Button>

          {spot?.description &&
            Util.stripMarkdown(spot.description).replaceAll('\n', '').length >
              0 && (
              <WebUI.MarkdownParagraph
                className="line-clamp-3 text-ds-xs"
                markdown={spot.description}
              />
            )}
        </div>

        <div className="flex flex-col gap-0_5 text-ds-sm">
          {spot?.time_slots.map((ts) => (
            <div key={ts.id} className="flex flex-row gap-7">
              <div className="w-[126px]">
                {`${Util.nativeFormatDate(ts.options.startTime ?? '', {
                  timeStyle: 'short',
                  timeZone: row?.original.timeZone,
                })} - ${Util.nativeFormatDate(ts.options.endTime ?? '', {timeStyle: 'short', timeZone: row?.original.timeZone})}`}
              </div>
              <div>Available: {ts.available_quantity ?? 'Unlimited'}</div>
            </div>
          ))}
        </div>

        {spot?.hidden && (
          <div
            className={
              'absolute top-0 right-0 flex h-full w-[82px] flex-col items-center justify-center text-center text-natural-60'
            }
          >
            <WebUI.PhosphorIcon icon="eye-slash" width={32} />
          </div>
        )}
      </WebUI.Card>
    )
  },
)

// MARK: - SignUpScheduleSpotListBySpotSection

interface SignUpScheduleSpotListBySpotSectionProps
  extends WebUI.DataSectionComponentProps<SignUpScheduleSpotBySpotListSectionData> {
  onEdit: () => void
  onDelete: () => void
}

const SignUpScheduleSpotListBySpotSection = React.forwardRef<
  HTMLDivElement,
  SignUpScheduleSpotListBySpotSectionProps
>(
  (
    {setSelected, section, children, onEdit, onDelete, ...restProps},
    forwardedRef,
  ) => {
    const spot = section?.original
    return (
      <WebUI.Disclosure initialVisible={true} {...restProps}>
        {(disclosure) => (
          <WebUI.Card
            data-hidden={!!spot?.hidden}
            ref={forwardedRef}
            className="[&[data-hidden=true]_>_*:not(.Card-accessoryView)]:opacity-60"
            dragHandleVisible={!disclosure.visible}
            accessoryView={
              !!spot && (
                <SpotQuickActionGroup
                  spot={spot}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              )
            }
          >
            <div
              data-expanded={disclosure.visible}
              className={
                'relative flex flex-row p-6 data-[expanded=true]:border-b'
              }
            >
              <WebUI.DisclosureButton
                className="font-semibold [&_>_.Button-iconBefore]:mr-3"
                variant="text"
                iconBefore={
                  <WebUI.PhosphorIcon
                    className={
                      '-rotate-90 transition-transform duration-100 ease-linear [[aria-expanded=true]_>_.Button-iconBefore_>_&]:rotate-0'
                    }
                    icon="caret-down-fill"
                  />
                }
              >
                {section?.original.name}
              </WebUI.DisclosureButton>
              {spot?.hidden && (
                <div
                  className={
                    'absolute top-0 right-0 flex h-16 w-[82px] flex-col items-center justify-center text-center text-natural-60'
                  }
                >
                  <WebUI.PhosphorIcon icon="eye-slash" width={32} />
                </div>
              )}
            </div>

            <WebUI.DisclosureContent className="p-4">
              {children}
            </WebUI.DisclosureContent>
          </WebUI.Card>
        )}
      </WebUI.Disclosure>
    )
  },
)

// MARK: - SignUpScheduleSpotListBySpotRow

interface SignUpScheduleSpotListBySpotRowProps
  extends WebUI.DataRowComponentProps<SignUpScheduleSpotBySpotListRowData> {
  onEdit: (data: SignUpScheduleSpotBySpotListRowData) => void
}

const SignUpScheduleSpotListBySpotRow = React.forwardRef<
  HTMLDivElement,
  SignUpScheduleSpotListBySpotRowProps
>(({onEdit, row, setSelected, className, ...restProps}, forwardedRef) => {
  const spot = row?.original.spot
  return (
    <WebUI.Card
      ref={forwardedRef}
      className={WebUI.cn(
        'flex flex-col items-start justify-center gap-6 p-5 sm:flex-row sm:items-center sm:justify-start',
        className,
      )}
      {...restProps}
    >
      <div className="flex w-[240px] flex-col gap-2">
        <WebUI.Button
          variant="link"
          onClick={() => {
            if (row) {
              onEdit(row.original)
            }
          }}
        >
          {row?.original.date ?? ''}
        </WebUI.Button>

        {spot?.description &&
          Util.stripMarkdown(spot.description).replaceAll('\n', '').length >
            0 && (
            <WebUI.MarkdownParagraph
              className="line-clamp-3 text-ds-xs"
              markdown={spot.description}
            />
          )}
      </div>

      <div className="flex flex-col gap-0_5">
        {Util.sort(row?.original.timeSlots ?? [])
          .asc((ts) => ts.options.startTime)
          .map((ts) => (
            <div key={ts.id} className="flex flex-row gap-7 text-ds-sm">
              <div className="w-[126px]">
                {`${Util.nativeFormatDate(ts.options.startTime ?? '', {
                  timeStyle: 'short',
                  timeZone: row?.original.timeZone,
                })} - ${Util.nativeFormatDate(ts.options.endTime ?? '', {
                  timeStyle: 'short',
                  timeZone: row?.original.timeZone,
                })}`}
              </div>
              <div>Available: {ts.available_quantity ?? 'Unlimited'}</div>
            </div>
          ))}
      </div>
    </WebUI.Card>
  )
})

// MARK: – SpotEmptyStateView

const SpotEmptyStateView: React.FC = () => null
