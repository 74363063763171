import {BooleanParam, useQueryParam} from 'use-query-params'
import React, {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import ImagesUtils from 'src/helpers/ImagesUtils'
import useCart from 'src/views/c/hooks/useCart'
import {getItemAmountType} from 'src/components/ItemAmountDisplay'
import * as Util from '@cheddarup/util'
import {SharpImage} from 'src/components/SharpImage'
import ItemViewImagePlaceholderIcon from 'src/images/item-view-image-placeholder.svg'
import {ItemViewPrice} from 'src/components'
import {useIsItemViewSoldOut} from 'src/views/c/utils/cart-item-utils'
import {PayerBrandKitColors} from '@cheddarup/core'

// MARK: – ItemViewRow

export interface ItemViewListItemProps
  extends React.ComponentPropsWithoutRef<'div'> {
  itemView: Api.PublicTabItem
  brandKitColors: PayerBrandKitColors
}

export const ItemViewRow = React.forwardRef<
  HTMLDivElement,
  ItemViewListItemProps
>(({className, itemView, brandKitColors, ...restProps}, forwardedRef) => {
  const [preview] = useQueryParam('preview', BooleanParam)
  const {cart} = useCart()
  const [hasImages, setHasImages] = useState(itemView.images.length > 0)

  const isSoldOut = useIsItemViewSoldOut({itemId: itemView.id})
  const waitlistEnabled = isItemViewWaitlistEnabled(cart, itemView)
  const isAvailableQuantityVisible =
    !itemView.options.variants?.enabled &&
    itemView.options.makeAvailableQuantityPublic &&
    itemView.available_quantity != null

  const addToCartButton = (
    <WebUI.VStack className="shrink-0 gap-2 pl-0 sm:items-center sm:justify-center sm:p-4">
      {isAvailableQuantityVisible && (
        <WebUI.Ellipsis className="text-ds-sm text-orange-50">
          {getCartItemAvailableQuantity(cart, itemView)} Left
        </WebUI.Ellipsis>
      )}
      <WebUI.Button
        className="w-[8.5rem]"
        variant={
          preview || isSoldOut
            ? waitlistEnabled
              ? 'secondaryAlt'
              : 'secondary'
            : 'default'
        }
        roundness="capsule"
        style={{
          backgroundColor: brandKitColors.primaryButton,
          color: Util.getReadableColor(brandKitColors.primaryButton),
        }}
      >
        {(() => {
          if (preview) {
            return 'Preview'
          }
          if (isSoldOut) {
            return waitlistEnabled ? 'Join Waitlist' : 'Sold Out'
          }
          if (getItemAmountType(itemView) === 'open') {
            return 'Enter Amount'
          }
          return 'Add to Cart'
        })()}
      </WebUI.Button>
    </WebUI.VStack>
  )

  return (
    <WebUI.HStack
      ref={forwardedRef}
      className={WebUI.cn(
        'aria-disabled:cursor-not-allowed aria-invalid:border-primary',
        className,
      )}
      as={WebUI.Panel}
      {...restProps}
    >
      <WebUI.VStack className="min-h-0 min-w-0 flex-[1_1_0px] justify-between gap-2 p-4 pr-2 sm:gap-4 sm:pr-4">
        <WebUI.VStack className="h-full gap-0_5 text-ds-sm">
          <WebUI.Ellipsis className="text-ds-base">
            {itemView.name}
          </WebUI.Ellipsis>
          {itemView.required && (
            <WebUI.Text className="text-orange-50">Required</WebUI.Text>
          )}
          <ItemViewPrice
            itemView={itemView}
            listing={
              itemView.options.variants?.enabled
                ? Util.firstBy(
                    itemView.options.variants.listings,
                    (l) => l.amount,
                  )
                : undefined
            }
          />
        </WebUI.VStack>
        <div className="sm:hidden">{addToCartButton}</div>
      </WebUI.VStack>
      {hasImages && (
        <SharpImage
          className="object-cover object-center"
          alt={itemView.name}
          width={128}
          height={128}
          image={ImagesUtils.getMainImage(
            itemView.images,
            itemView.options.variants?.enabled
              ? itemView.images.find(
                  (image) => image.metadata.thumbnail?.order === 0,
                )?.id
              : undefined,
          )}
          onError={() => setHasImages(false)}
        />
      )}
      <WebUI.VStack className="hidden min-w-[20%] items-center justify-center border-l sm:flex">
        {addToCartButton}
      </WebUI.VStack>
    </WebUI.HStack>
  )
})

// MARK: – ItemViewGridItem

export interface ItemViewGridItemProps
  extends React.ComponentPropsWithoutRef<'div'> {
  itemView: Api.PublicTabItem
}

export const ItemViewGridItem = React.forwardRef<
  HTMLDivElement,
  ItemViewGridItemProps
>(({className, itemView, ...restProps}, forwardedRef) => {
  const {cart} = useCart()

  const hasImages = itemView.images.length > 0

  const isSoldOut = useIsItemViewSoldOut({itemId: itemView.id})
  const waitlistEnabled = isItemViewWaitlistEnabled(cart, itemView)

  return (
    <WebUI.Panel
      ref={forwardedRef}
      className={WebUI.cn('relative', className)}
      {...restProps}
    >
      <div
        className={WebUI.cn(
          !hasImages && 'bg-natural-80',
          'relative h-0 w-full pt-[100%]',
        )}
      >
        <img
          className={WebUI.cn(
            hasImages ? 'h-full w-full' : 'w-[128px]',
            '-translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 block object-cover object-center',
          )}
          alt={itemView.name}
          src={
            hasImages
              ? (ImagesUtils.getItemMainThumbnailUrl(
                  itemView.images,
                  {width: 300},
                  itemView.options.variants?.enabled
                    ? itemView.images.find(
                        (image) => image.metadata.thumbnail?.order === 0,
                      )?.id
                    : undefined,
                ) as any)
              : ItemViewImagePlaceholderIcon
          }
        />
        {((!itemView.options.variants?.enabled &&
          itemView.options.makeAvailableQuantityPublic &&
          itemView.available_quantity !== null) ||
          isSoldOut) && (
          <div
            className={WebUI.cn(
              'absolute top-0 right-0 rounded rounded-t-none border-r-0 px-4 py-2',
              isSoldOut
                ? waitlistEnabled
                  ? 'bg-teal-70'
                  : 'bg-natural-80'
                : 'bg-tint',
            )}
          >
            <div
              className={WebUI.cn(
                'text-center font-light text-ds-sm',
                isSoldOut ? 'text-gray800' : 'text-natural-100',
              )}
            >
              {isSoldOut
                ? waitlistEnabled
                  ? 'Join Waitlist'
                  : 'Sold Out'
                : `${itemView.available_quantity} Left`}
            </div>
          </div>
        )}
      </div>
      <div className="flex h-[6rem] min-h-0 min-w-0 flex-auto flex-col justify-between p-4">
        <h5 className="block overflow-hidden text-ellipsis whitespace-nowrap text-ds-sm text-gray800 leading-compact">
          {itemView.name}
        </h5>
        {itemView.required && (
          <div className="font-normal text-ds-sm text-orange-50">Required</div>
        )}
        <ItemViewPrice
          className="text-tint"
          itemView={itemView}
          listing={
            itemView.options.variants?.enabled
              ? Util.firstBy(
                  itemView.options.variants.listings,
                  (l) => l.amount,
                )
              : undefined
          }
        />
      </div>
    </WebUI.Panel>
  )
})

// MARK: – Helpers

function getCartItemQuantity(cart: Api.Cart | undefined, itemViewId: number) {
  const cartItems =
    cart?.items.filter((item) => item.tab_item.id === itemViewId) ?? []
  return Util.sumBy(cartItems, (item) => item.quantity)
}

function getCartItemAvailableQuantity(
  cart: Api.Cart | undefined,
  itemView: Api.PublicTabItem,
) {
  const availableQuantity =
    itemView.available_quantity ?? Number.POSITIVE_INFINITY
  return availableQuantity - getCartItemQuantity(cart, itemView.id)
}

function isItemViewSoldOut(
  cart: Api.Cart | undefined,
  itemView: Api.PublicTabItem,
) {
  if (itemView.options.variants?.enabled) {
    const maxListingQuantity = Math.max(
      ...itemView.options.variants.listings
        .map((l) => l.available_quantity)
        .filter((q) => q != null),
    )

    return maxListingQuantity === 0
  }

  return getCartItemAvailableQuantity(cart, itemView) === 0
}

function isItemViewWaitlistEnabled(
  cart: Api.Cart | undefined,
  itemView: Api.PublicTabItem,
) {
  return (
    !!itemView.options.waitlist?.enabled && isItemViewSoldOut(cart, itemView)
  )
}
