import {BooleanParam, useQueryParam} from 'use-query-params'
import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {bannedWordIncludedIn} from 'src/helpers/inputHelpers'
import * as Util from '@cheddarup/util'

import useCart from '../hooks/useCart'
import usePublicCollection from '../hooks/usePublicCollection'
import type {CheckoutFormik} from './CheckoutPage'
import {CheckoutSteppedPanel} from './CheckoutSteppedPanel'
import {useActiveCheckoutStep} from './CheckoutPageFlowContext'
import {PayerBrandKitColors} from '@cheddarup/core'

export interface PaymentMethodTypePanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  formik: CheckoutFormik
  brandKitColors: PayerBrandKitColors
}

export const PaymentMethodTypePanel = ({
  formik,
  brandKitColors,
  ...restProps
}: PaymentMethodTypePanelProps) => {
  const [addPayment] = useQueryParam('add-payment', BooleanParam)
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const activeStep = useActiveCheckoutStep()

  if (
    activeStep !== 'payment' ||
    !(
      addPayment ||
      publicCollection.acceptsEcheck ||
      ((publicCollection.acceptsCash || addPayment) &&
        (!cart || cart.recurringTotal === 0))
    )
  ) {
    return null
  }

  return (
    <CheckoutSteppedPanel
      step="payment"
      heading="How would you like to pay?"
      {...restProps}
    >
      <WebUI.RadioGroup
        aria-label="Payment method type"
        name="paymentMethod.method"
        state={formik.values.paymentMethod.method}
        onChange={formik.handleChange}
        onBlur={formik.handleChange}
      >
        <WebUI.Radio
          checkedRadioIconStyles={{
            backgroundColor: brandKitColors.secondaryButton,
          }}
          value="card"
        >
          Credit Card
        </WebUI.Radio>
        {(publicCollection.acceptsEcheck || addPayment) && (
          <WebUI.Radio
            checkedRadioIconStyles={{
              backgroundColor: brandKitColors.secondaryButton,
            }}
            value="echeck"
          >
            eCheck
          </WebUI.Radio>
        )}
        {(publicCollection.acceptsCash || addPayment) &&
          (!cart || cart.recurringTotal === 0) && (
            <WebUI.Radio
              checkedRadioIconStyles={{
                backgroundColor: brandKitColors.secondaryButton,
              }}
              value="cash"
            >
              Cash or Check
            </WebUI.Radio>
          )}
      </WebUI.RadioGroup>
      {formik.values.paymentMethod.method === 'cash' && (
        <OfflinePaymentInstructions className="mt-4 ml-8" />
      )}
    </CheckoutSteppedPanel>
  )
}

// MARK: – OfflinePaymentInstructions

const OfflinePaymentInstructions: React.FC<
  Omit<WebUI.MarkdownParagraphProps, 'markdown'>
> = ({className, ...restProps}) => {
  const {publicCollection} = usePublicCollection()

  const instructions = bannedWordIncludedIn(
    publicCollection.offlinePaymentInstructions,
  )
    ? publicCollection.userManagesCollection
      ? 'Your instructions contain restricted language (mention of another payment platform) and will not appear at checkout. This field is closely monitored and if a workaround is entered, your collection may be at risk of being shut down, with payments auto refunded.'
      : ''
    : publicCollection.offlinePaymentInstructions ?? ''

  if (Util.stripMarkdown(instructions).trim().length === 0) {
    return null
  }

  return (
    <WebUI.MarkdownParagraph
      className={WebUI.cn('rounded bg-gray100 p-4 text-ds-sm', className)}
      markdown={instructions}
      {...restProps}
    />
  )
}
