import {BrandKitColors} from '@cheddarup/core'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'

interface TotalCollectedProps extends React.ComponentPropsWithoutRef<'div'> {
  totalCollected: Api.GroupPageSection
  brandKitColors: BrandKitColors
}

const TotalCollected = ({
  totalCollected,
  className,
  brandKitColors,
  style,
  ...restProps
}: TotalCollectedProps) => (
  <div
    className={WebUI.cn('flex flex-col px-2 py-18 xl:px-0', className)}
    style={{
      color: Util.getReadableColor(
        brandKitColors[totalCollected.background_color],
        undefined,
        true,
      ),
      backgroundColor: brandKitColors[totalCollected.background_color],
      ...style,
    }}
    {...restProps}
  >
    <div className="mx-auto w-full max-w-[1100px] px-6">
      <div className="flex flex-col gap-5 sm:max-w-[60%]">
        <WebUI.Heading className="font-accentAlt text-h-6 sm:text-h-4">
          {totalCollected.headline}
        </WebUI.Heading>
        <WebUI.Text className="font-light text-ds-md">
          {totalCollected.description}
        </WebUI.Text>
        <div className="flex flex-col gap-2">
          <WebUI.Heading className="font-body font-extrabold text-h-4 sm:text-h2">
            <WebUI.NumberTicker
              prefix="$"
              variant="slow"
              value={extractNumber(totalCollected.total_collected ?? '')}
            />
          </WebUI.Heading>
          {totalCollected.details.timePeriod && (
            <WebUI.Text className="text-ds-lg">
              {timePeriodToLabelMap[totalCollected.details.timePeriod]}
            </WebUI.Text>
          )}
        </div>
      </div>
    </div>
  </div>
)

// MARK: - Helpers

export const timePeriodToLabelMap = {
  all_time: 'All-Time',
  last_year: 'Year-to-Date',
  last_month: 'Month-to-Date',
  last_week: 'Week-to-Date',
}

function extractNumber(str: string): number {
  const numStr = str.replace(/[^0-9.]/g, '')
  return Number.parseFloat(numStr)
}

export default TotalCollected
